import React, { useState } from "react";
import { Link } from "gatsby";
import {
  FaBars,
  FaTimes,
  FaTwitter,
  FaDiscord,
  FaSteam,
  FaReddit,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import Img from "gatsby-image";
/* App imports */
import useEvent from "../../hooks/useEvent";
import style from "./header.module.less";
import Config from "../../../../config";
import Utils from "../../../utils";

const Header = ({ logo, siteDescription = Config.siteIndieDescr }) => {
  const [isMenuCollapsed, setMenuCollapsed] = useState(false);
  const [isHeaderCollapsed, setHeaderCollapsed] = useState(false);
  function toggleFixedHeader() {
    if (!isHeaderCollapsed && window.scrollY > 100) {
      setHeaderCollapsed(true);
    } else if (isHeaderCollapsed && window.scrollY < 100) {
      setHeaderCollapsed(false);
    }
  }

  function toggleMenu() {
    setMenuCollapsed(!isMenuCollapsed);
  }

  useEvent("scroll", toggleFixedHeader);

  return (
    <div
      className={style.container}
      style={isHeaderCollapsed ? { backgroundImage: "none" } : null}
    >
      <div className={style.titleContainer}>
        <div className={style.title}>
          <Link to={Utils.resolvePageUrl(Config.pages.home)}>
            <div className={style.logoWithTitle}>
              <div className={style.logo}>
                <Img fixed={logo.childImageSharp.fixed} />
              </div>
              <h4
                className={
                  isHeaderCollapsed
                    ? style.hiddenDescription
                    : style.visibleDescription
                }
              >
                {siteDescription}
              </h4>
            </div>
          </Link>
        </div>
        <div className={style.menuButton}>
          {isMenuCollapsed ? (
            <FaBars size="30" onClick={toggleMenu} />
          ) : (
            <FaTimes size="30" onClick={toggleMenu} />
          )}
        </div>
      </div>
      <div
        className={[
          style.list,
          isMenuCollapsed ? style.collapsedMenu : style.expandedMenu,
        ].join(" ")}
      >
        <ul>
          <li>
            <Link to={Utils.resolvePageUrl(Config.pages.home)}>Home</Link>
          </li>
          <li>
            <Link to={Utils.resolvePostUrl(Config.pages.indie)}>Indie</Link>
          </li>
          <li>
            <Link to={Utils.resolvePostUrl(Config.pages.casual)}>Casual</Link>
          </li>
          <li>
            <Link to={Utils.resolvePostUrl(Config.pages.feedback)}>
              Feedback
            </Link>
          </li>
          <li>
            <Link to={Utils.resolvePostUrl(Config.pages.about)}>About</Link>
          </li>
        </ul>
        <ul>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.facebook}
            >
              <FaFacebook size="30" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.twitter}
            >
              <FaTwitter size="30" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.reddit}
            >
              <FaReddit size="30" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.discord}
            >
              <FaDiscord size="30" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.youtube}
            >
              <FaYoutube size="30" />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="nofollow noopener noreferrer"
              href={Config.social.steam}
            >
              <FaSteam size="30" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
